:root {
  --color-body: #4d5968;
  --color-heading: #2a3139;
  --color-link: #ea9239;
  --color-link-light: #eea65d;
  --color-link-dark: #d97817;
  --color-primary: #ea9239;
  --color-primary-light: #eea65d;
  --color-primary-dark: #d97817;
  --color-secondary: #c6cace;
  --color-secondary-light: #dbdee1;
  --color-secondary-dark: #aab0b6;
  --color-background: #dbdbdb;
  --color-font-primary: #ffffff;
  --color-font-secondary: #ffffffcc;
  --color-font-idle: #ffffff6d;
  /* Modo Oscuro */

  --dark-background-color: #001526; /* Fondo oscuro */
  --dark-background-secondary: #01223d; /* Fondo oscuro */
  --dark-background-color-gradient: radial-gradient(circle, var(--dark-background-secondary) 0, var(--dark-background-color) 90%); /* Fondo oscuro */
  --dark-text-color: #e0e0e0; /* Texto claro */
  --dark-accent-color: #bb86fc; /* Acento en lavanda claro */
  --dark-secondary-accent-color: #03dac6; /* Acento en turquesa claro */
  --dark-outline-color: #e0e0e0d4; /* Texto claro */

  /* Modo Claro */
  --light-background-color: #ffffff; /* Fondo claro */
  --light-text-color: #333333; /* Texto oscuro */
  --light-accent-color: #6200ee; /* Acento en violeta oscuro */
  --light-secondary-accent-color: #018786; /* Acento en verde azulado oscuro */

  /* Colores Adicionales */
  --color-detected: #4caf50; /* Verde para indicación de detección */
  --color-inactive: #757575; /* Gris para elementos inactivos */
  --color-active: #f44336; /* Rojo para indicar actividad */

  /* Colores de las Teclas en Modo Oscuro */
  --dark-key-color: #333333; /* Color principal de las teclas en modo oscuro */
  --dark-key-text-color: #e0e0e0; /* Color del texto en las teclas para modo oscuro */
  --dark-key-active-color: #6200ee; /* Color de las teclas activas en modo oscuro */
  --dark-key-inactive-color: #555555; /* Color de las teclas inactivas en modo oscuro */

  /* Colores de las Teclas en Modo Claro */
  --light-key-color: #ffffff; /* Color principal de las teclas en modo claro */
  --light-key-text-color: #333333; /* Color del texto en las teclas para modo claro */
  --light-key-active-color: #6200ee; /* Color de las teclas activas en modo claro */
  --light-key-inactive-color: #cccccc; /* Color de las teclas inactivas en modo claro */
}
