@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

.loaderContainer {
  width: 100vw;
  min-width: 100vw;
  height: 100%;
  min-height: 100%;
  background: var(--dark-background-color-gradient);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  position: absolute;
  z-index: 10;

  .logo-container {
    background-color: var(--dark-text-color);
    border-radius: 100%;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);

    .logo {
      margin: 1.5em;
      width: 200px;
      height: 200px;
    }
  }

  h1 {
    margin-top: 1.5em;
    color: var(--dark-text-color);
    font-size: 2em;
    max-width: 60%;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
  }

  .loaderLogoContainer {
    background-color: var(--dark-text-color);
    border-radius: 100%;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
    padding: 1.5em;
    position: relative;
  }

  .loaderLogo {
    width: 200px;
    height: 200px;
  }
  .loaderLogoText {
    position: absolute;
    top: 60px;
    right: 65px;
    display: inline-block;
  }
  .loaderText {
    font-size: 20px;
    color: #253778;
  }

  .letter {
    font-size: 14px;
    color: #fff;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .showLetter {
    opacity: 1;
  }
}
